import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import YouTube from "react-youtube"
import Layout from "../components/layout"
import ImgTemplates from "../../static/images/dashboard-templates.png"
import ImgPhone from "../../static/images/mockup-frame.png"
import ImgFAQs from "../../static/images/faqs.jpg"
import ImgGlossary from "../../static/images/glossary.jpg"
import ImgCallum from "../../static/images/callum.png"
import ImgOscar from "../../static/images/oscar.png"
import ImgAisha from "../../static/images/aisha.png"
import BlueLine from "../../static/images/blue-line.svg"
import StoriesDots from "../../static/images/stories-dots.svg"
import PhoneDots from "../../static/images/phone-dots.svg"
import YellowDot from "../../static/images/large-yellow-dot.svg"
import ImgRob from "../../static/images/rob.png"
import ImgJo from "../../static/images/jo.png"
import YellowDots from "../../static/images/yellow-dots.svg"
import { AnimateOnEntry } from "../components/animatedImageOnScroll"
import { ImageScroller } from "../components/imageScroller"

const RobVideo = "OtUII1KlMk"
const VideoExample = "OtUIIk1KlMk"

const Explore = () => (
  <Layout>
    <Helmet>
      <title>For healthcare | IBDmate</title>
    </Helmet>
    <div className="layout-explore">
      <section className="white-bg" id="intro-healthcare">
        <div className="wrapper">
          <div className="content">
            <h1>Providing better patient IBD education.</h1>
            <p>IBDmate is a digital IBD education platform to support your patients and their families to increase their disease knowledge, confidence and skills in managing their IBD.</p>
            <p>Research in Crohn’s disease shows that those with adequate health literacy are more likely to be in clinical remission<sup><a href="#ref1">1</a></sup>, but many patients tell us they don’t engage with the current information leaflets handed out in clinic - so we’ve created a better way to deliver patient education.</p>
          </div>
          <div className="dashboard-preview">
            <img src={ImgTemplates} alt="IBDmate dashboard" />
          </div>
        </div>
      </section>
      <section className="blue-bg">
        <div className="quote">
          <p>There is a strong association between patient activation (having the knowledge, skills, and confidence to effectively manage one's care) and IBD clinical remission<sup><a href="#ref2">2</a></sup></p>
        </div>
      </section>
      <section className="features">
        <div className="wrapper content">
          <p>IBDmate has been developed in conjunction with patients and IBD clinical teams to provide ‘prescribable’ IBD information through an HCP dashboard* and delivered to the patient via a web-based platform.</p>
          <p>IBDmate provides:</p>
          <ul>
            <li>Hundreds of video lessons delivered by IBD experts and IBD patients</li>
            <li>A dashboard for clinical teams to prescribe relevant information to patients at pivotal points in their care*</li>
            <li>Patients an opportunity to hear from other patients going through the same as them</li>
            <li>Patients the ability to watch engaging, educational videos about all aspects of their disease from the comfort of their home</li>
            <li>Bespoke ‘Meet the Team’ videos from your clinical team for your patients to get to know who is going to be looking after them**</li>
            <li>An opportunity to re-skin the platform with your own hospital branding**</li>
          </ul>
        </div>
      </section>
      <section className="features blue-bg">
        <div className="wrapper content">
          <h2>Why I’m using IBDmate</h2>
          <p>Hear from Rob Heuschkel, consultant gastroenterologist and clinical director of children’s services at Cambridge University Hospitals NHS Trust.</p>
        </div>
        <YouTube videoId={RobVideo} containerClassName="video" />
      </section>
      <section className="features">
        <div className="wrapper content">
          <p>IBDmate currently includes courses on</p>
          <ul>
            <li>The diagnosis journey</li>
            <li>Understanding IBD</li>
            <li>Symptoms of IBD</li>
            <li>Treatment (medications and liquid diet)</li>
            <li>Tests and procedures</li>
            <li>Complications of IBD</li>
            <li>Patient stories and experiences</li>
          </ul>
          <p>With many more topics to be added soon.</p>
        </div>
      </section>
      <section className="blue-bg" id="videos">
        <div className="wrapper">
          <YouTube videoId={VideoExample} containerClassName="video" />
          <div className="content">
            <h2>Putting IBD information in the patients' hands</h2>
            <p>
              Videos and written content explore topics in depth, helping them
              build confidence in managing their IBD between hospital
              appointments.
            </p>
            <Link to="/videos" className="btn btn-purple">
              View more example videos
            </Link>
          </div>
        </div>
      </section>
      <section className="white-bg" id="trusted">
        <div className="content">
          <h2>Trusted information</h2>
          <p>More than 15 paediatric <Link to="/experts">IBD experts</Link> share their knowledge on everything patients need to know - from symptoms and diagnosis to tests, treatments and the future.</p>
          <p>IBDmate has been developed and tested with the paediatric IBD team at Addenbrooke’s Hospital in Cambridge.</p>
          <p>
            <Link to="/explore/">Learn more</Link>
          </p>
        </div>
        <div
          className="images"
          style={{ backgroundImage: `url(${YellowDots})` }}
        >
          <Link to="/experts/" className="image"><img src={ImgJo} alt="Jo" /></Link>
          <Link to="/experts/" className="image"><img src={ImgRob} alt="Rob" /></Link>
          <Link to="/experts/" className="btn btn-purple">See our experts</Link>
        </div>
      </section>
      <section
        className="blue-bg"
        id="stories"
        style={{ backgroundImage: `url(${BlueLine}),url(${StoriesDots})` }}
      >
        <div className="wrapper">
          <div className="story-content">
            <h2>Hear from patients</h2>
            <p>
              Young patients share their experiences of life with IBD and tips
              they’ve picked up along the way.
            </p>
          </div>
          <div className="story-images">
            <img src={ImgAisha} alt="Aisha" />
            <img src={ImgOscar} alt="Oscar" />
            <img src={ImgCallum} alt="Callum" />
            <Link to="/patients/" className="btn btn-purple">See our patients</Link>
          </div>
        </div>
      </section>
      <section id="references">
        <div className="wrapper">
          <p>*With an IBDmate HCP standard subscription only</p>
          <p>**With an IBDmate premium subscription only</p>
          <p id="ref1">1. <a href="https://pubmed.ncbi.nlm.nih.gov/29992302/" target="_blank">https://pubmed.ncbi.nlm.nih.gov/29992302/</a></p>
          <p id="ref2">2. <a href="https://pubmed.ncbi.nlm.nih.gov/30590700/" target="_blank">https://pubmed.ncbi.nlm.nih.gov/30590700/</a></p>
        </div>
      </section>
    </div>
  </Layout>
)

export default Explore
